import React, { useState } from 'react';
import styled from 'styled-components';
import { Body5, Body6, Container, H3, Page } from '../libs/nvstr-common-ui.es';
import { Body } from '../components/layout/Body';
import { Header } from '../components/navigation';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useLatestDocs } from '../hooks/features/useLatestDocs';
import { DocRow, LatestDocsList } from '../containers/DigitalAnalyst/DocumentList';
import { scrollToTop } from '../utils/usefulFuncs';
import { PageLoading } from '../components/UI/PageLoading';
import { ROUTES } from '../constants/routes';
import { Loading } from '../components/UI/Loading';
import { createRows } from '../utils';
import { PageError } from '../components/UI/PageError';
import { useCompanyData, useCompanyTagData } from '../hooks/features/useCompany';
import { useLatestCompanyDocs } from '../hooks/features/useLatestCompanyDocs';
import BackArrowButton from '../components/buttons/BackArrowButton';

const PageWrapper = styled.div``;
const BodyWrapper = styled.div`
  padding: 24px;
`;

function StockDetailDisplay({ id, security }) {
  const navigate = useNavigate();

  const [activeSecurity, setActiveSecurity] = useState(null);
  const [rows, setRows] = React.useState([]);
  const {
    latestDocDays,
    latestDocsByDayLookup,

    isLoading: isLoadingRecentDocs,
    getTickerDocData,
  } = useLatestDocs();

  React.useEffect(() => {
    if (security && !activeSecurity) {
      setActiveSecurity(security);
    }
    if (security && activeSecurity && security?.symbol !== activeSecurity?.symbol) {
      setActiveSecurity(security);
    }
  }, [security, activeSecurity]);

  React.useEffect(() => {
    if (activeSecurity) {
      const symbol = activeSecurity.symbol;
      getTickerDocData({ symbols: [symbol] });
    }
  }, [activeSecurity]);

  React.useEffect(() => {
    if (Array.isArray(latestDocDays)) {
      setRows(createRows(latestDocDays, latestDocsByDayLookup, []));
    }
  }, [latestDocDays, latestDocsByDayLookup]);

  const onGoBack = () => {
    navigate(ROUTES.home);
  };

  if (!security) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <BodyWrapper>
            <Page>
              <BackArrowButton onClick={onGoBack} />
              <Container top={36} bottom={16}>
                <Body6 bold>Something went wrong, please try again later...</Body6>
              </Container>
            </Page>
          </BodyWrapper>
        </Body>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <BodyWrapper>
          <Page>
            <BackArrowButton onClick={onGoBack} />
            <Container top={36} bottom={16}>
              {security?.symbol && (
                <H3 bold lineHeight={'28px'}>
                  {security?.symbol}
                </H3>
              )}
              <Container top={4}>
                <Body5>{security?.name}</Body5>
              </Container>
            </Container>

            <Container>
              <Container vertical={24}>
                {isLoadingRecentDocs || activeSecurity?.symbol !== security?.symbol ? (
                  <Loading />
                ) : (
                  <LatestDocsList rows={rows} enableSummaryMode />
                )}
              </Container>
            </Container>
          </Page>
        </BodyWrapper>
      </Body>
    </PageWrapper>
  );
}

function DocsList({ docs }) {
  return (
    <>
      {docs.map((d) => (
        <DocRow key={d.symbol} doc={d} isOneStock />
      ))}
    </>
  );
}

function CompanyTagDetail({ id }) {
  const { isLoading, company, error } = useCompanyTagData(id);

  if (isLoading) {
    return <PageLoading />;
  }

  if (error) {
    return <PageError message={error} />;
  }

  if (!company) {
    return <PageError message={'This company is currently unavailable. Please try again later...'} />;
  }

  return <CompanyDetailDisplay company={company} />;
}

function CompanyDetailDisplay({ company }) {
  const navigate = useNavigate();

  const [rows, setRows] = React.useState([]);
  const {
    docs,
    latestDocDays,
    latestDocsByDayLookup,

    isLoading: isLoadingRecentDocs,
    getDocData,
  } = useLatestCompanyDocs();

  React.useEffect(() => {
    getDocData({ company_ids: [company.id] });
  }, []);

  React.useEffect(() => {
    if (Array.isArray(latestDocDays)) {
      setRows(createRows(latestDocDays, latestDocsByDayLookup, []));
    }
  }, [latestDocDays, latestDocsByDayLookup]);

  const onGoBack = () => {
    navigate(ROUTES.home);
  };

  if (!company) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <BodyWrapper>
            <Page>
              <BackArrowButton onClick={onGoBack} />
              <Container top={36} bottom={16}>
                <Body6 bold>Something went wrong, please try again later...</Body6>
              </Container>
            </Page>
          </BodyWrapper>
        </Body>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <BodyWrapper>
          <Page>
            <BackArrowButton onClick={onGoBack} />
            <Container top={36} bottom={16}>
              <Container top={4}>
                <H3>{company?.name}</H3>
              </Container>
            </Container>

            <Container>
              <Container vertical={24}>
                {/*{isLoadingRecentDocs ? <Loading /> : <LatestDocsList rows={rows} enableSummaryMode />}*/}
                {isLoadingRecentDocs ? <Loading /> : <DocsList docs={docs} enableSummaryMode />}
              </Container>
            </Container>
          </Page>
        </BodyWrapper>
      </Body>
    </PageWrapper>
  );
}

function StockDetail({ id }) {
  const { isLoading, company, error } = useCompanyData(id);

  if (isLoading) {
    return <PageLoading />;
  }

  if (error) {
    return <PageError message={error} />;
  }

  if (!company) {
    return <PageError message={'This stock is currently unavailable. Please try again later...'} />;
  }

  return <StockDetailDisplay security={company} />;
}

export default function CompanyAndStockDetail() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [companyId, setCompanyId] = React.useState(null);
  const [docTagId, setDocTagId] = React.useState(null);

  React.useEffect(() => {
    const companyId = searchParams.get('id');
    const docTagId = searchParams.get('dtid');
    if (companyId) {
      setCompanyId(companyId);
      setDocTagId(null);
    }
    if (docTagId) {
      setDocTagId(docTagId);
      setCompanyId(null);
    }
    if (!companyId && !docTagId) {
      navigate(ROUTES.home);
    }
  }, [searchParams]);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  if (companyId !== null) {
    return <StockDetail id={companyId} />;
  }

  if (docTagId !== null) {
    return <CompanyTagDetail id={docTagId} />;
  }

  return null;
}
