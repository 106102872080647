import React from 'react';
import styled from 'styled-components';
import { Body5, Container, H5, SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../libs/nvstr-common-ui.es';
import { useRecentDocsAndNewsData } from '../../hooks/features/useRecentDocsAndNews';
import { useColorTheme } from '../../hooks';
import { QuestionResult } from './components/QuestionResult';
import { useMultiplePrompts } from '../../hooks/features/useMultiplePrompts';
import { createTimeInstance, formatLocalizedDateTime } from '../../libs/nvstr-utils.es';
import { useAdvancedSettings } from '../../hooks/application/useAdvancedSettings';

const Wrapper = styled.div`
  ul {
    margin: 0;
    padding: 4px 0 0 12px;
  }

  li {
    margin: 0 0 10px 0;
  }
`;
const DropdownContainer = styled.div`
  margin: 0 0 8px -4px;

  select {
    width: 180px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.themeColors.text};
    padding: 12px 16px;

    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }
`;
const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const SummarySectionWrapper = styled.div``;

const intro = 'Only answer with this criteria, companies that mention';
const middle =
  'No outside commentary is needed. Beginning with the largest and most important, and a maximum of 5 companies total, explain in 1 short bullet per company, if company mentioned the criteria, any major specific updates relating';
const closing = `Put company names in bold. Do not use numbered bullet lists. Do not repeat the same company twice. Keep it short and to the point. Importantly: If there is no mention of the criteria, do not explicitly state 'No mention of X found' for that company instead exclude it from your response`;

const newEarningsPrompt = `${intro} company earnings or company outlook. ${middle} to company revenue, company net income, and company guidance metrics if mentioned. Include any values and figures if mentioned. ${closing}`;
const newMAndAPrompt = `${intro} specific merger and acquisition activity. ${middle} to acquiring, divesting, or merging with another company, or being acquired by another company. Include specific purchase prices and metrics if mentioned. ${closing}`;
const newStockAndBuybacksPrompt = `${intro} Stock Issuance and Buybacks. ${middle} to issuing stock or stock buybacks, include amounts, pricing, and/or share prices if mentioned. ${closing}`;
const newDebtIssuancePrompt = `${intro} Bond and Loan Issuance. ${middle} to issuing bonds and/or loans, include specific purchase prices, amounts, interest rates, and metrics if mentioned. ${closing}`;

const sections = [
  {
    heading: 'Earnings Highlights',
    prompt: newEarningsPrompt,
  },
  {
    heading: 'M&A',
    prompt: newMAndAPrompt,
  },
  {
    heading: 'Stock Issuance and Buybacks',
    prompt: newStockAndBuybacksPrompt,
  },
  {
    heading: 'Debt Issuance',
    prompt: newDebtIssuancePrompt,
  },
];

const SummarySection = ({ data: { resultId, heading }, model }) => {
  return (
    <SummarySectionWrapper>
      <Body5>{heading}</Body5>
      {resultId && (
        <Container top={8}>
          <QuestionResult resultId={resultId} question={null} collapseHeight hideRating />
        </Container>
      )}
    </SummarySectionWrapper>
  );
};

const tool = 'recent_docs_summary';

const SummarySections = ({ docs, model, day }) => {
  const responses = useMultiplePrompts(docs, sections, model, day, tool);

  if (responses === null) {
    return null;
  }

  return (
    <RowWrapper>
      {responses.map((r) => (
        <SummarySection key={r.heading} model={model} data={r} />
      ))}
    </RowWrapper>
  );
};

function getDocData(key, docs) {
  let day = null;

  if (key === 'Recent') {
    day = 'Recent';
  } else if (key === 'Today') {
    day = formatLocalizedDateTime('api', createTimeInstance());
  } else if (key === 'Yesterday') {
    day = formatLocalizedDateTime('api', createTimeInstance().subtract(1, 'day'));
  } else {
    day = formatLocalizedDateTime('api', createTimeInstance(key));
  }

  return docs.filter((d) => d.day === day)[0];
}

function SummaryForDay({ activeKey, docs, model }) {
  const docData = getDocData(activeKey, docs);
  const day = docData?.day;
  const data = docData?.docs;

  if (!data) {
    return null;
  }

  return (
    <Container top={8}>
      <SummarySections docs={data} model={model} day={day} />
    </Container>
  );
}

const buildDaySelectionOptionDateString = (d) => {
  const date = createTimeInstance(d);
  const isToday = createTimeInstance().isSame(date, 'day');
  const isYest = createTimeInstance().subtract(1, 'day').isSame(date, 'day');
  if (d === 'Recent') {
    return 'Recent';
  }
  if (isToday) {
    return 'Today';
  }
  if (isYest) {
    return 'Yesterday';
  }
  return formatLocalizedDateTime('api2', date);
};

export function DailySummary() {
  const colorTheme = useColorTheme();
  const { model } = useAdvancedSettings();

  const { docs, isLoading } = useRecentDocsAndNewsData();

  const [activeDay, setActiveDay] = React.useState(null);
  const [dayOptions, setDayOptions] = React.useState([]);

  React.useEffect(() => {
    if (docs) {
      const days = docs.map((d) => buildDaySelectionOptionDateString(d.day));
      setDayOptions(days);
    }
  }, [docs]);

  React.useEffect(() => {
    if (activeDay === null) {
      if (docs?.length > 0) {
        const days = docs.map((d) => buildDaySelectionOptionDateString(d.day));
        setActiveDay(days[0]);
      }
    }
  }, [docs, activeDay]);

  if (isLoading || !docs || activeDay === null) {
    return (
      <Wrapper>
        <Container bottom={0}>
          <H5>Summary</H5>
        </Container>
        <Container top={40} bottom={80}>
          <Container row verticallyCenter>
            <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} color={colorTheme.text} />
            <Container left={16}>
              <Body5 bold>Loading Recent Company Documents</Body5>
            </Container>
          </Container>
        </Container>
      </Wrapper>
    );
  }

  const handleDayChange = (e) => {
    const value = e?.target?.value;
    setActiveDay(value);
  };

  return (
    <Wrapper>
      <Container bottom={16}>
        <H5>Summary</H5>
      </Container>
      <DropdownContainer>
        <select value={activeDay} onChange={handleDayChange}>
          {dayOptions.map((o) => (
            <option>{o}</option>
          ))}
        </select>
      </DropdownContainer>
      <Container>
        <SummaryForDay activeKey={activeDay} docs={docs} model={model} />
      </Container>
    </Wrapper>
  );
}
