import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import { Container, H5, Page } from '../libs/nvstr-common-ui.es';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { createTimeInstance, SCREEN_SIZES, TrackingEvent } from '../libs/nvstr-utils.es';
import { scrollToTop } from '../utils/usefulFuncs';
import { MultiColumnPage } from '../components/UI/MultiColumnPage';
import { useGetRecentDocs, useGetRecentNews } from '../hooks/features/useRecentDocsAndNews';
import { NewsArticles } from '../containers/news/NewsArticles';
import { DailyDocsList } from '../containers/DigitalAnalyst/DailyDocsList';
import { FilterForm } from '../containers/DigitalAnalyst/components/DocFilters';
import { AdvancedSettingsButton } from '../containers/DigitalAnalyst/AdvancedSettings';
import { AdvancedSettingsFields, useAdvancedSettings } from '../hooks/application/useAdvancedSettings';
import { DailySummary } from '../containers/DigitalAnalyst/DailySummary';
import { applicationActionTypes } from '../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { AI_TOOLS, CURRENT_DEFAULT_MODEL } from '../constants';
import { useScreenSize } from '../hooks/application/useWindowSize';
import { ColumnTabs } from '../containers/UI/ColumnTabs';
import { useWatchlist } from '../hooks/features/useWatchlist';
import { useCurrentUserToolsAvailable } from '../hooks/user/useCurrentUser';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const SideBySidePages = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  margin: 0 16px;
`;

const tickerFilterKey = 'tfk';
const getTickerFiltersFromStorage = () => {
  const v = window.localStorage.getItem(tickerFilterKey);
  return v === null || typeof v !== 'string' ? '' : v;
};
const setTickerFiltersFromStorage = (v) => {
  window.localStorage.setItem(tickerFilterKey, v);
};

const defaultTickers = getTickerFiltersFromStorage();

const defaultMinMarketCap = 5000000000;
const defaultMaxMarketCap = 5000000000000;
const defaultFilterDocTypes = [];
const defaultStartDate = createTimeInstance().subtract(7, 'days');
const defaultOnlyWatchlist = defaultTickers.length > 0 ? false : false;
const noWatchlistTickers = null;

const filterSettingsFilterKey = 'fskey';
const getSavedFiltersFromStorage = () => {
  const v = window.localStorage.getItem(filterSettingsFilterKey);
  if (v === null || v === 'null' || typeof v !== 'string') {
    return {
      minMarketCap: defaultMinMarketCap,
      maxMarketCap: defaultMaxMarketCap,
      docTypes: defaultFilterDocTypes,
      startDate: defaultStartDate,
      ignoreTickers: false,
      onlyWatchlist: defaultOnlyWatchlist,
    };
  }
  return JSON.parse(v);
};
const setSavedFiltersToStorage = (v) => {
  window.localStorage.setItem(filterSettingsFilterKey, JSON.stringify(v));
};

const advancedSettingsFields = [
  AdvancedSettingsFields.model,
  AdvancedSettingsFields.headlinesToggle,
  AdvancedSettingsFields.dailySummariesToggle,
];

const TAB_NAMES = {
  docs: 'Documents',
  news: 'Headlines',
  summary: 'Summary',
};

export function LatestUpdatesAndNews() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screenSize = useScreenSize();

  const permissions = useCurrentUserToolsAvailable();
  const isWatchlistPermissionEnabled = permissions.includes(AI_TOOLS.WATCHLIST);

  const [DailySummaryComponent] = React.useState(<DailySummary key={'daily-summary'} />);
  const [NewsArticlesComponent] = React.useState(<NewsArticles key={'news-headlines'} />);
  const [DailyDocsListComponent] = React.useState(<DailyDocsList key={'daily-docs-list'} />);

  const [tabNames, setTabNames] = React.useState([]);
  const [BodyComponents, setBodyComponents] = React.useState([]);

  const { watchlist } = useWatchlist();

  const { enableDailySummaries: showDailySummary, enableHeadlines } = useAdvancedSettings();

  const {
    minMarketCap: savedMinMarketCap,
    maxMarketCap: savedMaxMarketCap,
    docTypes: savedDocTypes,
    startDate: savedStartDate,
    ignoreTickers: savedIgnoreTickers,
    onlyWatchlist: savedOnlyWatchlist,
  } = getSavedFiltersFromStorage();
  const [minMarketCap, setMinMarketCap] = React.useState(savedMinMarketCap);
  const [maxMarketCap, setMaxMarketCap] = React.useState(savedMaxMarketCap);
  const [startDate, setStartDate] = React.useState(savedStartDate);
  const [filterDocTypes, setFilterDocTypes] = React.useState(savedDocTypes);
  const [tickers, setTickers] = React.useState(defaultTickers);
  const [ignoreTickers, setIgnoreTickers] = React.useState(savedIgnoreTickers);
  const [onlyWatchlist, setOnlyWatchlist] = React.useState(savedOnlyWatchlist);

  useGetRecentNews();
  useGetRecentDocs(
    minMarketCap,
    maxMarketCap,
    startDate,
    filterDocTypes,
    tickers,
    ignoreTickers,
    onlyWatchlist && isWatchlistPermissionEnabled ? watchlist : noWatchlistTickers
  );

  const onGoBack = () => navigate(ROUTES.home);

  React.useEffect(() => {
    scrollToTop();

    // set default adv settings
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: {
        enableHeadlines: false,
        enableDailySummaries: true,
        model: CURRENT_DEFAULT_MODEL,
      },
    });
  }, []);

  React.useEffect(() => {
    if (screenSize === SCREEN_SIZES.tablet) {
      const tabs = [];
      const components = [];
      enableHeadlines && tabs.push(TAB_NAMES.news);
      enableHeadlines && components.push(NewsArticlesComponent);

      showDailySummary && tabs.push(TAB_NAMES.summary);
      showDailySummary && components.push(DailySummaryComponent);

      setTabNames(tabs);
      setBodyComponents(components);
    }
    if (screenSize === SCREEN_SIZES.mobile) {
      const tabs = [TAB_NAMES.docs];
      const components = [DailyDocsListComponent];
      enableHeadlines && tabs.push(TAB_NAMES.news);
      enableHeadlines && components.push(NewsArticlesComponent);

      showDailySummary && tabs.push(TAB_NAMES.summary);
      showDailySummary && components.push(DailySummaryComponent);

      setTabNames(tabs);
      setBodyComponents(components);
    }
  }, [screenSize, enableHeadlines, showDailySummary]);

  const resetFilters = () => {
    setMinMarketCap(defaultMinMarketCap);
    setMaxMarketCap(defaultMaxMarketCap);
    setFilterDocTypes(defaultFilterDocTypes);
    setStartDate(defaultStartDate);
    setOnlyWatchlist(defaultOnlyWatchlist);
    setTickers('');
    setIgnoreTickers(false);
    setTickerFiltersFromStorage('');
    setSavedFiltersToStorage(null);

    TrackingEvent.create('Reset Recent Docs Filters', {});
  };

  const onFiltersChange = (values) => {
    const { minMarketCap, maxMarketCap, docTypes, startDate, tickers, ignoreTickers, onlyWatchlist } = values;
    setMinMarketCap(minMarketCap);
    setMaxMarketCap(maxMarketCap);
    setFilterDocTypes(docTypes);
    setStartDate(startDate);
    setTickers(tickers);
    setOnlyWatchlist(onlyWatchlist);
    setIgnoreTickers(ignoreTickers);

    setTickerFiltersFromStorage(tickers);
    setSavedFiltersToStorage({ minMarketCap, maxMarketCap, docTypes, startDate, ignoreTickers, onlyWatchlist });

    TrackingEvent.create('Update Recent Docs Filters', {
      'Market Cap Min': minMarketCap,
      'Market Cap Max': maxMarketCap,
      'Doc Types': docTypes,
      'Start Date': startDate,
      Tickers: tickers,
      'Only Watchlist': onlyWatchlist,
      'Toggle Saved Tickers On': ignoreTickers,
    });
  };

  if (screenSize === SCREEN_SIZES.mobile) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <Page>
            <BackArrowButton onClick={onGoBack} />
            <AdvancedSettingsButton fields={advancedSettingsFields} top={8} />

            <Container top={36}>
              <H5>Latest Updates</H5>
            </Container>
            <Container>
              <FilterForm
                minMarketCap={minMarketCap}
                maxMarketCap={maxMarketCap}
                docTypes={filterDocTypes}
                startDate={startDate}
                tickers={tickers}
                ignoreTickers={ignoreTickers}
                onFiltersChange={onFiltersChange}
                onResetFilters={resetFilters}
                onlyWatchlist={onlyWatchlist}
                enableWatchlist={isWatchlistPermissionEnabled}
                enableMarketCap
                enableTickers
                enableWatchlistLink
              />
            </Container>
            <Container top={24}>
              <ColumnTabs tabNames={tabNames} BodyComponents={BodyComponents} />
            </Container>
          </Page>
        </Body>
      </PageWrapper>
    );
  }

  if (screenSize === SCREEN_SIZES.tablet) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <SideBySidePages>
            <MultiColumnPage>
              <BackArrowButton onClick={onGoBack} />
              <AdvancedSettingsButton fields={advancedSettingsFields} top={8} />

              <Container top={36}>
                <H5>Sources</H5>
              </Container>
              <Container>
                <FilterForm
                  minMarketCap={minMarketCap}
                  maxMarketCap={maxMarketCap}
                  docTypes={filterDocTypes}
                  startDate={startDate}
                  tickers={tickers}
                  ignoreTickers={ignoreTickers}
                  onFiltersChange={onFiltersChange}
                  onResetFilters={resetFilters}
                  onlyWatchlist={onlyWatchlist}
                  enableWatchlist={isWatchlistPermissionEnabled}
                  enableMarketCap
                  enableTickers
                  enableWatchlistLink
                />
              </Container>
              <Container top={24}>
                <DailyDocsList />
              </Container>
            </MultiColumnPage>
            {showDailySummary || enableHeadlines ? (
              <MultiColumnPage>
                {showDailySummary && enableHeadlines ? (
                  <ColumnTabs tabNames={tabNames} BodyComponents={BodyComponents} />
                ) : showDailySummary ? (
                  DailySummaryComponent
                ) : (
                  NewsArticlesComponent
                )}
              </MultiColumnPage>
            ) : null}
          </SideBySidePages>
        </Body>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <SideBySidePages>
          <MultiColumnPage>
            <BackArrowButton onClick={onGoBack} />
            <AdvancedSettingsButton fields={advancedSettingsFields} top={8} />

            <Container top={36}>
              <H5>Sources</H5>
            </Container>
            <Container>
              <FilterForm
                minMarketCap={minMarketCap}
                maxMarketCap={maxMarketCap}
                docTypes={filterDocTypes}
                startDate={startDate}
                tickers={tickers}
                ignoreTickers={ignoreTickers}
                onFiltersChange={onFiltersChange}
                onResetFilters={resetFilters}
                onlyWatchlist={onlyWatchlist}
                enableWatchlist={isWatchlistPermissionEnabled}
                enableMarketCap
                enableTickers
                enableWatchlistLink
              />
            </Container>
            <Container top={24}>{DailyDocsListComponent}</Container>
          </MultiColumnPage>
          {enableHeadlines && (
            <MultiColumnPage>
              <Container>{NewsArticlesComponent}</Container>
            </MultiColumnPage>
          )}
          {showDailySummary && <MultiColumnPage>{DailySummaryComponent}</MultiColumnPage>}
        </SideBySidePages>
      </Body>
    </PageWrapper>
  );
}
