import React from 'react';
import { DocumentSelection } from '../DocumentSelection';
import { Loading } from '../../../components/UI/Loading';
import { useClientData } from '../../../hooks/features/useClientData';
import { useFilteredDocs } from '../../../hooks/features/useDocs';
import { GRID_MODES } from '../../gridMode';

const CUSTOM_MATRIX_GRID_MODES = [GRID_MODES.client, GRID_MODES.clientPeerAnalysis];

export const PresetDocumentSelection = ({
  presetParams,
  selectedDocs,
  handleAddDocument,
  handleRemoveDocument,
  onDismiss,
}) => {
  const [concatDocs, setConcatDocs] = React.useState([]);

  const clientData = useClientData();

  const { tickers, isQueryMode, isResearchMode, isWebMode, docMode } = presetParams;
  const [filters] = React.useState({ tickers, isUserOnlyMode: true, isQueryMode, isResearchMode, isWebMode, docMode });
  const { docs, isLoading: isLoadingDocs } = useFilteredDocs(filters, null);

  React.useEffect(() => {
    if (clientData.customDefinedDocs && CUSTOM_MATRIX_GRID_MODES.includes(docMode)) {
      setConcatDocs(clientData.customDefinedDocs);
    }
  }, [clientData]);

  React.useEffect(() => {
    if (docs && !CUSTOM_MATRIX_GRID_MODES.includes(docMode)) {
      setConcatDocs(docs);
    }
  }, [docs]);

  if (CUSTOM_MATRIX_GRID_MODES.includes(docMode) && !clientData.customDefinedDocs) {
    return <Loading />;
  }

  if (!CUSTOM_MATRIX_GRID_MODES.includes(docMode) && isLoadingDocs) {
    return <Loading />;
  }

  return (
    <DocumentSelection
      tickers={presetParams.tickers}
      docs={concatDocs}
      selectedDocs={selectedDocs}
      onSelect={handleAddDocument}
      onRemoveSelect={handleRemoveDocument}
      onClose={onDismiss}
      callWithEntireDoc
    />
  );
};
