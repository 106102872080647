import React from 'react';
import styled from 'styled-components';
import { Container } from '../libs/nvstr-common-ui.es';

export const InfoIconTypes = {
  QUERY_TOOL: 'query-tool',
  FED_GPT: 'fed-gpt-tool',
  CSV: 'csv',
  FED_RELEASE: 'fed-release',
  COMPLIANCE_SEARCH: 'compliance-search',
  SOURCES: 'sources',
};

const Heading = styled.div`
  font-size: 16px;
  font-weight: bold;
`;
const Content = styled.div`
  font-size: 12px;
  line-height: 16px;
  user-select: none;

  color: ${({ theme }) => theme.themeColors.text};

  * {
    color: ${({ theme }) => theme.themeColors.text};
  }

  strong {
    font-weight: bold;
  }
`;

export const convertInfoIconWordKeyToEventName = (type) =>
  ({
    [InfoIconTypes.QUERY_TOOL]: 'Query Tool Heading',
    [InfoIconTypes.FED_GPT]: 'Fed GPT Heading',
    [InfoIconTypes.FED_RELEASE]: 'Fed Release Heading',
    [InfoIconTypes.CSV]: 'Input',
    [InfoIconTypes.COMPLIANCE_SEARCH]: 'Compliance Search',
    [InfoIconTypes.SOURCES]: 'Sources',
  }[type]);

const QueryToolHeadingInfoIcon = () => {
  return (
    <Content>
      <Heading>Ask Digital Analyst</Heading>
      <p>
        Have questions about company filings, earnings calls, or other documents? Our digital analyst is here to answer
        them and save you time.
      </p>
      <p>
        <strong>Getting Started:</strong>
      </p>
      <ol>
        <li>
          Type in a company name or ticker. The most recent documents for that company will be automatically selected as
          sources.
        </li>
        <li>To explore older documents, click "Select Documents" and choose the ones you need.</li>
        <li>Ask your question. You can type it manually or use one of our pre-filled questions for guidance.</li>
        <li>
          Hit "Submit". Our analyst will provide an answer along with relevant source information in the context
          section.
        </li>
      </ol>

      <p>
        <strong>Advanced:</strong>
      </p>
      <p>
        If you want to inquire about documents not tied to a specific company (like ones you've uploaded yourself), you
        can leave the ticker field blank. Simply click "Select Documents" to choose your document and proceed with your
        question.
      </p>
    </Content>
  );
};

const FedGPT = () => {
  return (
    <Content>
      <Heading>Ask FedGPT</Heading>
      <p>
        FedGPT answers your questions about Federal Reserve transcripts, testimony, press releases and more,
        accelerating your research process.
      </p>
      <p>
        <strong>Getting Started:</strong>
      </p>
      <ol>
        <li>
          <p>
            Ask any question about Fed statements or policy, such as "What indicators is the Federal Reserve most
            closely monitoring to gauge the sustainability of the current disinflationary trend?"
          </p>
        </li>
        <li>
          <p>
            Recent Fed sources are selected by default. Press "Select document" to choose specific sources to use in
            answering the query.
          </p>
        </li>
        <li>
          <p>Press "Submit" to see FedGPT's answer along with source context, and suggested follow-up questions.</p>
        </li>
        <li>
          <p>You can rate your answer from 1-5 at the bottom of the page</p>
        </li>
      </ol>
    </Content>
  );
};

const FedRelease = () => {
  return (
    <Content>
      <Heading>Fed Release Analysis</Heading>
      <p>This tool helps you quickly analyze and compare statements from Fed speakers in a tabular format.</p>
      <p>
        <strong>Getting Started:</strong>
      </p>
      <ol>
        <li>
          <p>
            Add your own questions about Fed documents, or select from standard questions and saved favorites, using the
            "+ Add question" button.
          </p>
        </li>
        <li>
          <p>
            To ask a question that compares two documents, such as "How did the language regarding inflation targets
            change between these documents?" make sure the "Include adjacent document" checkbox is checked when you add
            your question.
          </p>
        </li>
        <li>
          <p>
            Select documents to query using the "+ Select" button. You can search by speaker name, date range, or
            document type and name.
          </p>
        </li>
        <li>
          <p>Press "Run analysis" to generate Answers.</p>
        </li>
      </ol>
    </Content>
  );
};

const CsvEntry = () => {
  return (
    <Content>
      <Heading>Add Tickers</Heading>
      <p>
        Add a stock ticker to filter by. If you wish to filter by multiple tickers, use commas to separate each ticker.
      </p>
    </Content>
  );
};

const ComplianceSearch = () => {
  return (
    <Content>
      <Heading>Compliance Search</Heading>
      <p>Multiple values can be added when separated by a comma.</p>
      <p>Add specific emails (optional), or leave blank to get all organizational users.</p>
      <p>Add specific keyword search terms.</p>
    </Content>
  );
};

const Sources = () => {
  return (
    <Content>
      <Container style={{ minWidth: '300px' }}>
        <Heading>Sources</Heading>
        <p>
          Answers to your query will be based on the selected source documents. You can add and remove sources documents
          here. Click on a name to view the original document.
        </p>
      </Container>
    </Content>
  );
};

export const getDefinitionComponent = (type, symbol) => {
  switch (type) {
    case InfoIconTypes.QUERY_TOOL: {
      return <QueryToolHeadingInfoIcon />;
    }
    case InfoIconTypes.FED_GPT: {
      return <FedGPT />;
    }
    case InfoIconTypes.FED_RELEASE: {
      return <FedRelease />;
    }
    case InfoIconTypes.CSV: {
      return <CsvEntry />;
    }
    case InfoIconTypes.COMPLIANCE_SEARCH: {
      return <ComplianceSearch />;
    }
    case InfoIconTypes.SOURCES: {
      return <Sources />;
    }

    default:
      return null;
  }
};
